<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.change_email') }}</h1>
      </header>

      <div class="mb-4">
        <p>
          {{ $t('message.currently_logged') }} <strong>{{ user.email }}</strong><br><br>
          {{ $t('message.email_usage') }}
        </p>
      </div>
      <div id="reset_password_form">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="$t('message.label_email') + '*'"
            required
          ></v-text-field>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>{{ $t('message.validate') }}
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'UpdateEmail',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    email: ''
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.checkUpdateEmail()
    document.title = config.title + ' - ' + this.$t('message.change_email')
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.updateEmail()
      }
    },
    checkUpdateEmail () {
      const userId = this.$store.getters.getUser.id

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/check-update-email/' + userId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              if (data.length > 10) {
                this.successMessage = data
              }

              this.errors = []
            }

            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    },
    updateEmail () {
      const userId = this.$store.getters.getUser.id

      const data = {
        email: this.email.toLowerCase()
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/update-email/' + userId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data.message
              this.errors = []
            }

            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    }
  }
}
</script>
